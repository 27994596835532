'use client';

import { useCallback, useEffect, useState } from 'react';
import { getHomePage, getSectionFromPage } from '@/lib/api';
import { Menu, Section } from '@/lib/model';
import { getChannelPref } from '@/i18n/userChannel';
import { defaultChannel } from '@/i18n/config';
import { Footer } from '@/app/components/footer';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { MainHeaderSidebarClient } from '../components/header/main-header-sidebar-wrapper/client';

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

const RightSection = dynamic(() =>
  import('@/app/components/common/rightRail').then(d => d.RightSection),
);

export default function Error({ error, reset }: Props) {
  const t = useTranslations();
  const [menu, setMenu] = useState<Menu | undefined>();
  const [rightSection, setRightSection] = useState<Section | undefined>();
  const channelPref = useGetChannelPref();

  const loadData = useCallback(async () => {
    const { channelPref } = await getChannelPref();
    const channel = channelPref || defaultChannel;
    const page = await getHomePage(channel.id, channel.languageCode);
    const section = getSectionFromPage(page, 'right_rail');
    setRightSection(section);
  }, []);

  useEffect(() => {
    const getMenuData = async () => {
      const response = await fetch(
        `/api/home-menu?channelId=${channelPref.id}&languageCode=${channelPref.languageCode}`,
      );
      if (!response.ok) {
        setMenu(undefined);
        return;
      }
      const data = await response.json();
      setMenu(data);
    };
    getMenuData();
    loadData().then();
    console.error(error);
  }, [error, loadData]);

  return (
    <>
      <title>
        {t('seo-not-found-title') || `TRT Global - ${channelPref.name || ''}`}
      </title>

      <MainHeaderSidebarClient menu={menu} />
      <main>
        <div className="pt-12">
          <div className="flex flex-col gap-15 w-full main-sized-wrapper mx-auto">
            <div className="w-full flex flex-col xl:grid 2xl:grid-cols-16 xl:grid-cols-12 gap-8">
              <div className="w-full 2xl:col-span-11 xl:col-span-8 flex flex-col justify-start pb-20">
                <div className="text-grey-12 font-notoserif 2xl:text-4xl lg:text-3.5xl sm:text-2.5xl text-2xl font-medium 2xl:leading-normal lg:leading-10 sm:leading-8 leading-7">
                  {t('error-page-title')}
                </div>
                <div className="mt-6 mb-10 text-grey-8 font-notosans 2xl:text-xl lg:text-lg sm:text-base text-sm lg:leading-6.5 leading-normal">
                  {t('error-page-desc')}
                </div>
                <div>
                  <div
                    onClick={reset}
                    className="px-4 py-2 inline-block cursor-pointer font-notosans font-semibold text-sm bg-cyan-500 text-white rounded-full shadow-sm"
                  >
                    {t('error-page-reset')}
                  </div>
                </div>
              </div>
              <div className="w-full 2xl:col-span-5 xl:col-span-4 xl:block hidden">
                {rightSection && <RightSection section={rightSection} />}
              </div>
            </div>
          </div>
        </div>
      </main>
      {menu && <Footer clientSideMenu={menu} channel={channelPref} />}
    </>
  );
}
